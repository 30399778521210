import request from '@/utils/request'
import qs from 'qs'
import { encode } from 'js-base64'

const AccessToken = '7bc98d929da735fe'
const private_key =
  '-----BEGIN PRIVATE KEY-----\n' +
  'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBALQZ5yhAlVT4zwY4nf6PNWQZt42BcRmhCCm4xJ7YOmfK/G3y1se5euZhdDRtpYs5p7W9KmYuaCQBtwnYYnxWvdX7uPkPQr3BKRdZe8/4PYZ1gnV5EtYOWYeLya8Mro5B/f/SAcfkEdmQqVcfUbXjt+/2rbZeNGN6rFtvbS+heLcbAgMBAAECgYBgw/oa+nhBq10wLoNVHoTFP7wLX5zw9EB6ije6UNswoTN3r54OuW17Eqlo8fCcGWIul3CMbiquDe3WcFiaDdD4nG/C2ZQJ3xHmcS+DD8A9OXg3WYLJr+19rAfOwWbJGcY7bdox6L5iG5dB+uVSLnXvGHNNnl17jl4QYK2rtzhMwQJBAOaZoR0prXprX+I37PHPcUBasWU2jrl3v6Zc08lmXL44EbG7iZ9lQ19QTIqxioZdY+bfSltty+Hvigee00b+TYkCQQDH8FMjOUoRFps0r0CGn8ZxoiH/I6Yz/1DE9cdVY2UhIqGzWrBcWA58ipV8HY8OT4sOP2L0W60tAoFTWOZWFjqDAkAsQPVjZFkvciW/gx4MtdO1FvKOSu8O9sUnxw65ytQniRkUM7pQhZ1tUDqwLyw3ZvrCFr3tV9/VpyOlHLzNFgaJAkB68LGOVSnP/CCPcruwjApP0SwqYFdQXcvicGaTgLysdUkMqdUL0zGFLwnf+aT/XbXZmuxT6RNBO+sdvupTQPPVAkEAutSaoeYdmtkbTBrGnlwXL7MXa0cp1RVr6pViSrKECfEU6QNoKAOz4u1b6LQt8pEiE/56++BWQoZzgxsmI2KJAQ==\n' +
  '-----END PRIVATE KEY-----'
// 'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBALQZ5yhAlVT4zwY4nf6PNWQZt42BcRmhCCm4xJ7YOmfK/G3y1se5euZhdDRtpYs5p7W9KmYuaCQBtwnYYnxWvdX7uPkPQr3BKRdZe8/4PYZ1gnV5EtYOWYeLya8Mro5B/f/SAcfkEdmQqVcfUbXjt+/2rbZeNGN6rFtvbS+heLcbAgMBAAECgYBgw/oa+nhBq10wLoNVHoTFP7wLX5zw9EB6ije6UNswoTN3r54OuW17Eqlo8fCcGWIul3CMbiquDe3WcFiaDdD4nG/C2ZQJ3xHmcS+DD8A9OXg3WYLJr+19rAfOwWbJGcY7bdox6L5iG5dB+uVSLnXvGHNNnl17jl4QYK2rtzhMwQJBAOaZoR0prXprX+I37PHPcUBasWU2jrl3v6Zc08lmXL44EbG7iZ9lQ19QTIqxioZdY+bfSltty+Hvigee00b+TYkCQQDH8FMjOUoRFps0r0CGn8ZxoiH/I6Yz/1DE9cdVY2UhIqGzWrBcWA58ipV8HY8OT4sOP2L0W60tAoFTWOZWFjqDAkAsQPVjZFkvciW/gx4MtdO1FvKOSu8O9sUnxw65ytQniRkUM7pQhZ1tUDqwLyw3ZvrCFr3tV9/VpyOlHLzNFgaJAkB68LGOVSnP/CCPcruwjApP0SwqYFdQXcvicGaTgLysdUkMqdUL0zGFLwnf+aT/XbXZmuxT6RNBO+sdvupTQPPVAkEAutSaoeYdmtkbTBrGnlwXL7MXa0cp1RVr6pViSrKECfEU6QNoKAOz4u1b6LQt8pEiE/56++BWQoZzgxsmI2KJAQ==\n' +

function encryptData(data) {
  let string = ''
  const obj = flat(data)
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== '') {
      string = `${string}&${key}=${obj[key]}`
    }
  }

  string = string.substring(1)
  console.log(string)
  const forge = window.forge
  const privKey = forge.pki.privateKeyFromPem(private_key)
  const md = forge.md.sha256.create()
  const _buffer = string
  md.update(_buffer, 'utf8')
  const sig = privKey.sign(md)
  const erg = forge.util.encode64(sig)
  return erg
}

function flat(obj = {}, preKey = '', res = {}) {
  // 空值判断，如果obj是空，直接返回
  if (!obj) return
  // 获取obj对象的所有[key,value]数组并且遍历，forEach的箭头函数中用了解构
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // 如果obj是数组，那么key就是数组的index，value就是对应的value
      // obj是数组的话就用[]引起来
      // 因为value是数组，数组后面是直接跟元素的，不需要.号
      const temp = Array.isArray(obj) ? `${preKey}[${key}]` : `${preKey}${key}`
      flat(value, temp, res)
    } else if (typeof value === 'object') {
      // 因为value是对象类型，所以在末尾需要加.号
      const temp = Array.isArray(obj)
        ? `${preKey}[${key}].`
        : `${preKey}${key}.`
      flat(value, temp, res)
    } else {
      const temp = Array.isArray(obj) ? `${preKey}[${key}]` : `${preKey}${key}`
      res[temp] = value
    }
  })
  return res
}

export function openCreate(data) {
  Object.keys(data).map((key) => {
    if (!data[key]) delete data[key]
  })
  return request({
    url: '/pay/pay/openCreate',
    method: 'post',
    headers: {
      'X-OPREAL-AccessToken': AccessToken,
      'X-OPREAL-Signature': encryptData(data),
    },
    data,
  })
}

export function openWebGet(data) {
  // const accessToken = localStorage.getItem('accessToken' + data.clientOrderNo)
  // const token = localStorage.getItem('token' + data.clientOrderNo)

  return request({
    // url: '/api/pay/orderInfo/openWebGet',
    url: '/fep/v1/internalPay/openGet',
    headers: {
      // 'X-OPREAL-Signature': md5Data(data),
      // 'X-OPREAL-AccessToken': accessToken,
      // 'X-OPREAL-Token': token,
    },
    method: 'post',
    data,
  })
}

export function customsDeclare(data) {
  return request({
    url: '/pay/customsDeclare/declare',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-OPREAL-AccessToken': AccessToken,
      'X-OPREAL-Signature': encryptData(data),
    },
    method: 'post',
    data,
  })
}

export function openClose(data) {
  return request({
    url: '/pay/pay/openClose',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-OPREAL-AccessToken': AccessToken,
      'X-OPREAL-Signature': encryptData(data),
    },
    method: 'post',
    data,
  })
}

// A+品牌获取
export function openConsult(data) {
  return request({
    url: '/pay/consultPayment/openConsult',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-OPREAL-Signature': encryptData(data),
      'X-OPREAL-AccessToken': AccessToken,
    },
    data,
  })
}

export function openCreateV2(data) {
  return request({
    url: '/pay/v2/pay/openCreate',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-OPREAL-Signature': encryptData(data),
      'X-OPREAL-AccessToken': AccessToken,
    },
    data: data,
  })
}

export function authPrepare(data) {
  return request({
    url: '/pay/auth/prepare',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-OPREAL-Signature': encryptData(data),
      'X-OPREAL-AccessToken': AccessToken,
    },
    method: 'post',
    data,
  })
}

export function authApply(data) {
  return request({
    url: '/pay/auth/apply',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-OPREAL-Signature': encryptData(data),
      'X-OPREAL-AccessToken': AccessToken,
    },
    method: 'post',
    data,
  })
}

export function authCancel(data) {
  return request({
    url: '/pay/auth/cancel',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-OPREAL-Signature': encryptData(data),
      'X-OPREAL-AccessToken': AccessToken,
    },
    method: 'post',
    data,
  })
}

export function authQuery(data) {
  return request({
    url: '/pay/auth/query',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-OPREAL-Signature': encryptData(data),
      'X-OPREAL-AccessToken': AccessToken,
    },
    method: 'post',
    data,
  })
}

export function getConfig(data) {
  return request({
    url: '/pay/creditCard/getConfig',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-OPREAL-Signature': encryptData(data),
      'X-OPREAL-AccessToken': AccessToken,
    },
    method: 'post',
    data,
  })
}

export function allvaluePay(data) {
  // data.signature = SHA256Data(data, 'asudhii1h23usahduah')
  return request({
    url: '/pay/thirdparty/allvalue/authorization',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    method: 'post',
    data: qs.stringify(data),
  })
}

export function feqCocos(data) {
  const params = qs.stringify({
    ...data,
    accesstoken: AccessToken,
    signature: encryptData(data),
  }, { allowDots: true })
  // console.log(params)
  // const forge = window.forge
  // const _code = forge.util.encode64(params).toString('shift-jis')
  const _code = encode(params, true)
  // console.log(_code)
  location.replace(
    process.env.NODE_ENV === 'development'
      ? `${process.env.VUE_APP_BASE}/cocos/v1/transfer?code=${_code}`
      : `${process.env.VUE_APP_BASE}/cocos/v1/transfer?code=${_code}`
  )
  // return request({
  //   changeOrigin: true,
  //   url: '/fep/v1/transactions/cocos',
  //   headers: {
  //     'X-OPREAL-Signature': encryptData(data),
  //     'X-OPREAL-AccessToken': '7bc98d929da735fe',
  //     'Content-Type': 'application/json;charset=UTF-8',
  //     // 'content-type': 'application/x-www-form-urlencoded',
  //     'X-Requested-With': 'XMLHttpRequest',
  //   },
  //   method: 'post',
  //   data,
  // })
}
